import _classCallCheck from "/app/node_modules/@babel/runtime/helpers/esm/classCallCheck.js";
import "core-js/modules/es.function.name.js";
// Composition API
import { defineComponent, reactive, onMounted, watch } from 'vue';
import { useInit } from '@/services/shared/utils';
import { debounce } from 'lodash'; // Định nghĩa data type của component [props] bằng Typescript

var PropskillListItemComponentProps = function PropskillListItemComponentProps() {
  _classCallCheck(this, PropskillListItemComponentProps);
}; // Định nghĩa data type của component [data] bằng Typescript


var PropskillHeaderComponentData = function PropskillHeaderComponentData() {
  _classCallCheck(this, PropskillHeaderComponentData);
};

export default defineComponent({
  name: 'PropskillHeaderComponent',
  props: {
    searchText: {
      type: String,
      default: null
    },
    breadcrumbName: {
      type: String,
      default: null
    }
  },
  emits: ['onSearch'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;

    var _a, _b, _c, _d; // Setup Default


    var _useInit = useInit(),
        base = _useInit.base;

    var data = reactive({
      isLoading: false,
      isHomePage: false,
      searchText: props.searchText || '',
      breadcrumb: ((_a = base.route.meta) === null || _a === void 0 ? void 0 : _a.breadcrumb) && Array.isArray((_b = base.route.meta) === null || _b === void 0 ? void 0 : _b.breadcrumb) ? (_c = base.route.meta) === null || _c === void 0 ? void 0 : _c.breadcrumb : [],
      breadcrumbName: props.breadcrumbName || ''
    });
    watch(function () {
      return [props.searchText, props.breadcrumbName];
    }, function (newValue, oldValue) {
      data.searchText = newValue[0] || '';
      data.breadcrumb[2].text = newValue[1] || '';
      data.breadcrumbName = newValue[1] || '';
    }); // Functions

    var onSearch = debounce(function () {
      emit('onSearch', data.searchText);
    }, 300); // Life circle

    onMounted(function () {
      data.isHomePage = base.route.name === 'propskill';
    });
    return {
      // Base
      breadcrumb: data.breadcrumbName.length ? data.breadcrumb : ((_d = base.route.meta) === null || _d === void 0 ? void 0 : _d.breadcrumb) || [],
      // Component reactive data
      data: data,
      // Functions
      onSearch: onSearch
    };
  }
});