import _objectSpread from "/app/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _classCallCheck from "/app/node_modules/@babel/runtime/helpers/esm/classCallCheck.js";
// Composition API
import { defineComponent, reactive, watch } from 'vue';
import { useInit } from '@/services/shared/utils';
import SwiperCore, { Navigation, Autoplay } from 'swiper';
SwiperCore.use([Navigation, Autoplay]); // Định nghĩa data type của component [data] bằng Typescript

var PropskillBannerComponentProps = function PropskillBannerComponentProps() {
  _classCallCheck(this, PropskillBannerComponentProps);
}; // Định nghĩa data type của component [data] bằng Typescript


var PropskillBannerComponentData = function PropskillBannerComponentData() {
  _classCallCheck(this, PropskillBannerComponentData);
};

export default defineComponent({
  name: 'PropskillBannerComponent',
  components: {},
  props: {
    modelValue: {
      type: Array,
      default: null,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: null
    }
  },
  setup: function setup(props) {
    var _useInit = useInit(),
        base = _useInit.base;

    var data = reactive({
      modelValue: _objectSpread({}, props.modelValue),
      isLoading: props.isLoading
    });
    watch(function () {
      return props.isLoading;
    }, function (newValue, oldValue) {
      data.isLoading = newValue;
    });
    return {};
  }
});