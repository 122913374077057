import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-27146f42"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  key: 0,
  class: "post-media-component"
};
var _hoisted_2 = {
  key: 0,
  ref: "videoPlayer",
  class: "video-js vjs-theme-forest",
  preload: "auto",
  playsinline: "",
  autoplay: "",
  muted: "",
  loop: ""
};
var _hoisted_3 = {
  key: 1,
  class: "post-media-component__background youtube"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _a, _b;

  var _component_YouTube = _resolveComponent("YouTube");

  var _directive_observe_visibility = _resolveDirective("observe-visibility");

  var _directive_loading = _resolveDirective("loading");

  return _ctx.data.dataSource ? (_openBlock(), _createElementBlock("div", _hoisted_1, [((_a = _ctx.data.dataSource) === null || _a === void 0 ? void 0 : _a.videoType.toUpperCase()) === _ctx.PostMediaTypeEnum.VIDEO || ((_b = _ctx.data.dataSource) === null || _b === void 0 ? void 0 : _b.videoType.toUpperCase()) === _ctx.PostMediaTypeEnum.VIDEO_FILE ? (_openBlock(), _createElementBlock("video", _hoisted_2, null, 512
  /* NEED_PATCH */
  )) : _ctx.data.dataSource.videoType.toUpperCase() === _ctx.PostMediaTypeEnum.YOUTUBE ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [_ctx.data.isVisibleYoutubeVideo ? (_openBlock(), _createBlock(_component_YouTube, {
    key: 0,
    src: _ctx.data.dataSource.videoUrl,
    vars: _ctx.data.setUpYoutube,
    onReady: _ctx.onReady,
    ref: "youtube",
    width: "100%",
    height: "100%"
  }, null, 8
  /* PROPS */
  , ["src", "vars", "onReady"])) : _createCommentVNode("v-if", true)], 512
  /* NEED_PATCH */
  )), [[_directive_observe_visibility, _ctx.youtubeVisibilityChanged], [_directive_loading, !_ctx.data.isVisibleYoutubeVideo]]) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true);
}