import _objectSpread from "/app/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _classCallCheck from "/app/node_modules/@babel/runtime/helpers/esm/classCallCheck.js";
import "core-js/modules/es.number.constructor.js";
// Composition API
import { defineComponent, reactive } from 'vue';
import { useInit } from '@/services/shared/utils';
import { CategoryModel } from '@/services/features/elearning/elearning.model';
import { contentHide } from '@/services/shared/utils';

var PropskillCategoryComponentProps = function PropskillCategoryComponentProps() {
  _classCallCheck(this, PropskillCategoryComponentProps);
}; // Định nghĩa data type của component [data] bằng Typescript


var PropskillCategoryComponentData = function PropskillCategoryComponentData() {
  _classCallCheck(this, PropskillCategoryComponentData);
};

export default defineComponent({
  name: 'PropskillCategoryComponent',
  components: {},
  props: {
    modelValue: {
      type: CategoryModel,
      default: null,
      required: true
    },
    masterData: {
      type: Object,
      default: null
    },
    lengthName: {
      type: Number,
      default: 20
    }
  },
  setup: function setup(props) {
    var _useInit = useInit(),
        base = _useInit.base;

    var data = reactive({
      modelValue: _objectSpread({}, props.modelValue),
      masterData: props.masterData,
      lengthName: props.lengthName
    });
    return {
      contentHide: contentHide
    };
  }
});