import _objectSpread from "/app/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _classCallCheck from "/app/node_modules/@babel/runtime/helpers/esm/classCallCheck.js";
import { defineComponent, reactive, watch } from 'vue';
import { useInit } from '@/services/shared/utils';
import SwiperCore, { Navigation, Autoplay } from 'swiper';
import { ProgressCourseEnum, StatusRegisterEnum } from '@/services/shared/enum';
import { Constant } from '@/services/shared/constant';
import { parseToMoney } from '@/services/shared/utils';
SwiperCore.use([Navigation, Autoplay]); // Định nghĩa data type của component [data] bằng Typescript

var PropskillSideLessonTabComponentData = function PropskillSideLessonTabComponentData() {
  _classCallCheck(this, PropskillSideLessonTabComponentData);
}; // Định nghĩa data type của component [props] bằng Typescript


var PropskillSideLessonTabComponentProps = function PropskillSideLessonTabComponentProps() {
  _classCallCheck(this, PropskillSideLessonTabComponentProps);
};

export default defineComponent({
  name: 'PropskillSideLessonTabComponent',
  props: {
    activeTab: {
      type: String,
      default: ''
    },
    typeTab: {
      type: String,
      default: ''
    },
    modelValue: {
      type: Object,
      default: null
    },
    course: {
      type: Object,
      default: null
    },
    isLoading: {
      type: Boolean,
      default: null
    }
  },
  emits: ['onActiveSideLessonTabChange'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;

    // Setup Default
    var _useInit = useInit(),
        base = _useInit.base,
        userId = _useInit.userId; // Component reactive data


    var data = reactive({
      isLoading: props.isLoading,
      activeTab: props.activeTab,
      modelValue: _objectSpread({}, props.modelValue),
      course: _objectSpread({}, props.course)
    });
    watch(function () {
      return [props.activeTab, props.course, props.isLoading];
    }, function (newValue, oldValue) {
      data.activeTab = newValue[0];
      data.course = newValue[1];
      data.isLoading = newValue[2];
    });

    var loadIconTopic = function loadIconTopic(item) {
      var icon;

      if (item.isPassed === true) {
        icon = Constant.SIDE_TAB[1].complete;
      } else {
        icon = item.videoUrl && item.videoUrl != null ? Constant.SIDE_TAB[1].video : Constant.SIDE_TAB[1].text;
      }

      return icon;
    }; // Chuyển tab


    var switchTab = function switchTab(activeTab) {
      if (!activeTab) {
        return;
      }

      emit('onActiveSideLessonTabChange', activeTab);
    };

    return {
      isActive: false,
      // Component reactive data
      data: data,
      // Enum
      ProgressCourseEnum: ProgressCourseEnum,
      switchTab: switchTab,
      Constant: Constant,
      loadIconTopic: loadIconTopic,
      parseToMoney: parseToMoney,
      StatusRegisterEnum: StatusRegisterEnum
    };
  }
});