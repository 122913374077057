import "core-js/modules/es.function.name.js";
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-724b5662"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "category"
};
var _hoisted_2 = {
  class: "image-box"
};
var _hoisted_3 = ["src"];
var _hoisted_4 = {
  class: "category-content"
};
var _hoisted_5 = {
  class: "category-description text-font"
};
var _hoisted_6 = {
  class: "category-title"
};
var _hoisted_7 = {
  class: "amount-item"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_card = _resolveComponent("el-card");

  var _component_router_link = _resolveComponent("router-link");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_router_link, {
    class: "link-category",
    to: {
      name: 'propskill-detail-category',
      params: {
        id: _ctx.modelValue.id
      }
    }
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_el_card, {
        "body-style": {
          padding: '0px'
        },
        class: "category-info",
        shadow: "never"
      }, {
        default: _withCtx(function () {
          var _a;

          return [_createElementVNode("div", _hoisted_2, [_createElementVNode("img", {
            src: _ctx.modelValue.iconImgUrl,
            class: "image"
          }, null, 8
          /* PROPS */
          , _hoisted_3)]), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.modelValue.nameLocale[0].name), 1
          /* TEXT */
          ), _createElementVNode("div", _hoisted_7, "(" + _toDisplayString((_a = _ctx.modelValue.courseList) === null || _a === void 0 ? void 0 : _a.length) + " khoá học)", 1
          /* TEXT */
          )])])];
        }),
        _: 1
        /* STABLE */

      })];
    }),
    _: 1
    /* STABLE */

  }, 8
  /* PROPS */
  , ["to"])]);
}