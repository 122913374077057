import _objectSpread from "/app/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _classCallCheck from "/app/node_modules/@babel/runtime/helpers/esm/classCallCheck.js";
// Composition API
import { defineComponent, reactive, watch } from 'vue';
import { Constant } from '@/services/shared/constant';
import { PropskillDataGet, PropSkillTabEnum } from '@/services/shared/enum';
import SwiperCore, { Navigation, Autoplay } from 'swiper';
import { debounce } from 'lodash';
SwiperCore.use([Navigation, Autoplay]); // Định nghĩa data type của component [data] bằng Typescript

var PropskillFilterComponentData = function PropskillFilterComponentData() {
  _classCallCheck(this, PropskillFilterComponentData);
};

export default defineComponent({
  name: 'PropskillFilterComponent',
  components: {},
  emits: ['onActiveTypeChange', 'toggledialogCategory'],
  props: {
    activeTab: {
      type: String,
      default: ''
    },
    typeGet: {
      type: String,
      default: ''
    },
    filterTag: {
      type: Array,
      default: null
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var data = reactive({
      activeTab: props.activeTab,
      typeGet: props.typeGet,
      filterTag: _objectSpread({}, props.filterTag)
    });
    watch(function () {
      return [props.activeTab, props.typeGet];
    }, function (newValue) {
      data.activeTab = newValue[0];
      data.typeGet = newValue[1];
    }); // Chuyển tab

    var switchType = function switchType(typeGet) {
      data.typeGet = typeGet;
      emit('onActiveTypeChange', typeGet);
    }; // Functions


    var toggledialogCategory = debounce(function () {
      emit('toggledialogCategory', true);
    }, 300);
    return {
      Constant: Constant,
      PropskillDataGet: PropskillDataGet,
      PropSkillTabEnum: PropSkillTabEnum,
      data: data,
      switchType: switchType,
      toggledialogCategory: toggledialogCategory
    };
  }
});