// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/bg_filter.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n/**\n * Load global thông qua sass-loader\n * https://webdevetc.com/programming-tricks/vue3/vue3-guides/vue-3-global-scss-sass-variables/\n */\n.transition-opacity[data-v-0fbed94a] {\n  transition: opacity;\n}\n.duration-100[data-v-0fbed94a] {\n  transition-duration: 0.1s;\n}\n.opacity-0[data-v-0fbed94a] {\n  opacity: 0;\n}\n.opacity-100[data-v-0fbed94a] {\n  opacity: 100;\n}\n.lesson-container[data-v-0fbed94a] {\n  padding-top: 45px;\n  padding-bottom: 60px;\n  width: 1200px;\n  margin: 0 auto;\n}\n.topic-page-header[data-v-0fbed94a] {\n  width: 100%;\n  min-height: 47px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: center;\n  background-size: auto 70px, contain;\n  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);\n}\n.topic-page-header__breadcrumb[data-v-0fbed94a], .topic-page-header__breadcrumb > a[data-v-0fbed94a] {\n  font-style: normal;\n  font-weight: 500;\n  font-size: 13px;\n  color: #ffd6d3;\n  text-decoration: none;\n}\n.content[data-v-0fbed94a] {\n  min-height: 585px;\n}\n.empty[data-v-0fbed94a] {\n  background: #ffffff;\n  border: 1px solid #f2f2f2;\n  margin: 0 auto;\n  width: 833px;\n}\n.empty img[data-v-0fbed94a] {\n  width: 301px;\n  height: 164px;\n}\n.group-suggest[data-v-0fbed94a] {\n  margin-top: 20px;\n  width: 334px;\n}", ""]);
// Exports
module.exports = exports;
