import _objectSpread from "/app/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _classCallCheck from "/app/node_modules/@babel/runtime/helpers/esm/classCallCheck.js";
// Composition API
import { defineComponent, reactive, watch } from 'vue';
import { useInit } from '@/services/shared/utils';
import { CourseModel } from '@/services/features/elearning/elearning.model';
import PropskillCourseInfoComponent from '@/components/features/elearning/PropskillCourseInfoComponent.vue'; // Định nghĩa data type của component [data] bằng Typescript

var PropskillCourseSuggestionComponentData = function PropskillCourseSuggestionComponentData() {
  _classCallCheck(this, PropskillCourseSuggestionComponentData);
};

var PropskillCourseSuggestionComponentProps = function PropskillCourseSuggestionComponentProps() {
  _classCallCheck(this, PropskillCourseSuggestionComponentProps);
};

export default defineComponent({
  name: 'PropskillCourseSuggestionComponent',
  components: {
    PropskillCourseInfoComponent: PropskillCourseInfoComponent
  },
  props: {
    modelValue: {
      type: CourseModel,
      // type: Array as () => Array<CourseModel>,
      default: null,
      required: true
    },
    masterData: {
      type: Object,
      default: null
    },
    isLoading: {
      type: Boolean,
      default: null
    }
  },
  setup: function setup(props) {
    var _useInit = useInit(),
        base = _useInit.base;

    var data = reactive({
      isLoading: props.isLoading,
      modelValue: _objectSpread({}, props.modelValue),
      masterData: props.masterData
    });
    watch(function () {
      return props.isLoading;
    }, function (newValue, oldValue) {
      data.isLoading = newValue;
    });
    return {};
  }
});