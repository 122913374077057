// Composition API
import { defineComponent } from 'vue';
import { useInit } from '@/services/shared/utils';
import PropskillHeaderComponent from '@/components/features/elearning/PropskillHeaderComponent.vue';
export default defineComponent({
  name: 'PropskillHistoryStudyPage',
  components: {
    PropskillHeaderComponent: PropskillHeaderComponent
  },
  setup: function setup() {
    // Setup Default
    var _useInit = useInit(),
        base = _useInit.base;

    return {};
  }
});