import _classCallCheck from "/app/node_modules/@babel/runtime/helpers/esm/classCallCheck.js";
import { defineComponent, reactive, watch } from 'vue';
import { useInit } from '@/services/shared/utils';
import SwiperCore, { Navigation, Autoplay } from 'swiper';
import { PropSkillTabEnum } from '@/services/shared/enum';
import { Constant } from '@/services/shared/constant';
SwiperCore.use([Navigation, Autoplay]); // Định nghĩa data type của component [data] bằng Typescript

var PropskillChangeTabComponentData = function PropskillChangeTabComponentData() {
  _classCallCheck(this, PropskillChangeTabComponentData);
}; // Định nghĩa data type của component [props] bằng Typescript


var PropskillChangeTabComponentProps = function PropskillChangeTabComponentProps() {
  _classCallCheck(this, PropskillChangeTabComponentProps);
};

export default defineComponent({
  name: 'PropskillChangeTabComponent',
  el: '#tab',
  props: {
    Lead: {
      type: Object,
      default: null
    },
    activeTab: {
      type: String,
      default: ''
    }
  },
  emits: ['onActiveTabChange'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;

    // Setup Default
    var _useInit = useInit(),
        base = _useInit.base,
        userId = _useInit.userId; // Component reactive data


    var data = reactive({
      isLoading: false,
      activeTab: props.activeTab
    });
    watch(function () {
      return props.activeTab;
    }, function (newValue, oldValue) {
      data.activeTab = newValue;
    }); // Chuyển tab

    var switchTab = function switchTab(activeTab) {
      data.activeTab = activeTab;
      emit('onActiveTabChange', activeTab);
    };

    return {
      isActive: false,
      // Component reactive data
      data: data,
      // Enum
      PropSkillTabEnum: PropSkillTabEnum,
      switchTab: switchTab,
      Constant: Constant
    };
  }
});