import _objectSpread from "/app/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _classCallCheck from "/app/node_modules/@babel/runtime/helpers/esm/classCallCheck.js";
import { defineComponent, reactive, watch } from 'vue';
import { useInit } from '@/services/shared/utils';
import SwiperCore, { Navigation, Autoplay } from 'swiper';
import { ProgressCourseEnum } from '@/services/shared/enum';
import { Constant } from '@/services/shared/constant';
SwiperCore.use([Navigation, Autoplay]); // Định nghĩa data type của component [data] bằng Typescript

var PropskillSideTopicTabComponentData = function PropskillSideTopicTabComponentData() {
  _classCallCheck(this, PropskillSideTopicTabComponentData);
}; // Định nghĩa data type của component [props] bằng Typescript


var PropskillSideTopicTabComponentProps = function PropskillSideTopicTabComponentProps() {
  _classCallCheck(this, PropskillSideTopicTabComponentProps);
};

export default defineComponent({
  name: 'PropskillSideTopicTabComponent',
  props: {
    activeTab: {
      type: String,
      default: ''
    },
    typeTab: {
      type: String,
      default: ''
    },
    modelValue: {
      type: Object,
      default: null
    },
    isLoading: {
      type: Boolean,
      default: null
    }
  },
  emits: ['onActiveSideTopicTabChange'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;

    // Setup Default
    var _useInit = useInit(),
        base = _useInit.base,
        userId = _useInit.userId; // Component reactive data


    var data = reactive({
      isLoading: props.isLoading,
      activeTab: props.activeTab,
      modelValue: _objectSpread({}, props.modelValue)
    });
    watch(function () {
      return props.activeTab;
    }, function (newValue, oldValue) {
      data.activeTab = newValue;
    });
    watch(function () {
      return [props.isLoading];
    }, function (newValue, oldValue) {
      data.isLoading = newValue[1];
    });

    var loadIconTopic = function loadIconTopic(item) {
      var icon;

      if (item.isPassed === true) {
        icon = Constant.SIDE_TAB[1].complete;
      } else {
        icon = item.videoUrl && item.videoUrl != null ? Constant.SIDE_TAB[1].video : Constant.SIDE_TAB[1].text;
      }

      return icon;
    }; // Chuyển tab


    var switchTab = function switchTab(activeTab) {
      if (!activeTab) {
        return;
      }

      data.activeTab = activeTab;
      emit('onActiveSideTopicTabChange', activeTab);
    };

    return {
      isActive: false,
      // Component reactive data
      data: data,
      // Enum
      ProgressCourseEnum: ProgressCourseEnum,
      switchTab: switchTab,
      Constant: Constant,
      loadIconTopic: loadIconTopic
    };
  }
});