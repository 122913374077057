import _objectSpread from "/app/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _classCallCheck from "/app/node_modules/@babel/runtime/helpers/esm/classCallCheck.js";
// Composition API
import { defineComponent, onMounted, onUnmounted, onUpdated, reactive, ref, watch } from 'vue';
import videojs from 'video.js';
import { useInit, formatDate, encapsulateUrl } from '@/services/shared/utils';
import { PostMediaTypeEnum } from '@/services/features/group/group.model'; // Định nghĩa data type của component [props] bằng Typescript

var PropskillVideoComponentProps = function PropskillVideoComponentProps() {
  _classCallCheck(this, PropskillVideoComponentProps);
}; // Định nghĩa data type của component [data] bằng Typescript


var PropskillVideoComponentData = function PropskillVideoComponentData() {
  _classCallCheck(this, PropskillVideoComponentData);
};

export default defineComponent({
  name: 'PropskillVideoComponent',
  props: {
    dataSource: {
      type: Object,
      default: null
    },
    isThumbnail: {
      type: Boolean,
      default: false
    },
    setUpYoutube: {
      type: Object,
      default: null
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;

    // Setup Default
    var _useInit = useInit(),
        base = _useInit.base;

    var videoPlayer = ref(null);
    var youtube = ref(null);
    var data = reactive({
      isLoading: false,
      dataSource: props.dataSource ? _objectSpread({}, props.dataSource) : null,
      player: null,
      isVisibleYoutubeVideo: false,
      setUpYoutube: {
        modestbranding: 0,
        rel: 0,
        showinfo: 0,
        fs: 0,
        autoplay: 1,
        loop: 1,
        mute: 1
      }
    });
    watch(function () {
      return props.dataSource;
    }, function () {
      return data.dataSource = props.dataSource;
    }, {
      deep: true
    });

    var loadVideoPlayer = function loadVideoPlayer() {
      if (videoPlayer.value) {
        data.player = videojs(videoPlayer.value, {
          fill: true,
          controls: true,
          preload: 'auto'
        }, function () {
          data.player.src({
            type: 'video/mp4',
            src: data.dataSource.videoUrl
          });
        });
        var reloadTime = 0;
        data.player.on('error', function () {
          if (reloadTime >= 3) {
            return;
          }

          reloadTime++;
          data.player.addClass('vjs-waiting');
          data.player.error(null);
          setTimeout(function () {
            data.player.load();
          }, 3000);
        });
      }
    };

    var onReady = function onReady() {
      if (youtube.value) {
        youtube.value.seekTo(0, true);
        youtube.value.playVideo();
      }
    };

    var youtubeVisibilityChanged = function youtubeVisibilityChanged(visibility) {
      if (visibility) {
        data.isVisibleYoutubeVideo = true;
      }
    };

    onMounted(function () {
      return loadVideoPlayer();
    });
    onUpdated(function () {
      return loadVideoPlayer();
    });
    onUnmounted(function () {
      if (data.player) {
        data.player.dispose();
      }
    }); // Functions

    return {
      PostMediaTypeEnum: PostMediaTypeEnum,
      // Component reactive data
      videoPlayer: videoPlayer,
      data: data,
      youtube: youtube,
      onReady: onReady,
      // Functions
      formatDate: formatDate,
      encapsulateUrl: encapsulateUrl,
      youtubeVisibilityChanged: youtubeVisibilityChanged
    };
  }
});