import _objectSpread from "/app/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _classCallCheck from "/app/node_modules/@babel/runtime/helpers/esm/classCallCheck.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
// Composition API
import { defineComponent, reactive, watch } from 'vue';
import { HistoryStudyModel } from '@/services/features/elearning/elearning.model';
import { TitleImageEnum } from '@/services/shared/enum';
import { Constant } from '@/services/shared/constant'; // Định nghĩa data type của component [data] bằng Typescript

var PropskillCourseProfileComponenProps = function PropskillCourseProfileComponenProps() {
  _classCallCheck(this, PropskillCourseProfileComponenProps);
}; // Định nghĩa data type của component [data] bằng Typescript


var PropskillCourseProfileComponentData = function PropskillCourseProfileComponentData() {
  _classCallCheck(this, PropskillCourseProfileComponentData);
};

export default defineComponent({
  name: 'PropskillCourseProfileComponent',
  components: {},
  props: {
    modelValue: {
      type: HistoryStudyModel,
      default: null
    },
    masterData: {
      type: Object,
      default: null
    },
    isLoading: {
      type: Boolean,
      default: null
    }
  },
  setup: function setup(props) {
    var data = reactive({
      modelValue: _objectSpread({}, props.modelValue),
      masterData: props.masterData,
      isLoading: props.isLoading
    });
    watch(function () {
      return props.isLoading;
    }, function (newValue, oldValue) {
      data.isLoading = newValue;
    });

    var titleGetLevel = function titleGetLevel(title) {
      switch (title) {
        case TitleImageEnum.NEWBIE:
          return Constant.LEVEL_PROPSKILL.find(function (item) {
            return item.key === TitleImageEnum.NEWBIE;
          });

        case TitleImageEnum.STREET:
          return Constant.LEVEL_PROPSKILL.find(function (item) {
            return item.key === TitleImageEnum.STREET;
          });

        case TitleImageEnum.AMATEURS:
          return Constant.LEVEL_PROPSKILL.find(function (item) {
            return item.key === TitleImageEnum.AMATEURS;
          });

        case TitleImageEnum.PROFESSIONAL:
          return Constant.LEVEL_PROPSKILL.find(function (item) {
            return item.key === TitleImageEnum.PROFESSIONAL;
          });

        case TitleImageEnum.AWESOME:
          return Constant.LEVEL_PROPSKILL.find(function (item) {
            return item.key === TitleImageEnum.AWESOME;
          });

        default:
          return {};
      }
    };

    return {
      titleGetLevel: titleGetLevel
    };
  }
});