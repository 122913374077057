import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-6a3b34dc"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "group-suggest-page"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _a, _b;

  var _component_PropskillHeaderComponent = _resolveComponent("PropskillHeaderComponent");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_PropskillHeaderComponent, {
    searchText: (_b = (_a = _ctx.data) === null || _a === void 0 ? void 0 : _a.routeQueryParams) === null || _b === void 0 ? void 0 : _b.q,
    onOnSearch: _ctx.handleSearch
  }, null, 8
  /* PROPS */
  , ["searchText", "onOnSearch"])]);
}