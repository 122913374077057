import _objectSpread from "/app/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _classCallCheck from "/app/node_modules/@babel/runtime/helpers/esm/classCallCheck.js";
// Composition API
import { defineComponent, reactive, watch } from 'vue';
import { useInit } from '@/services/shared/utils';
import { CourseModel, InstructorModel } from '@/services/features/elearning/elearning.model';
import PropskillCourseInfoComponent from './PropskillCourseInfoComponent.vue';
import { PropskillDataGet } from '@/services/shared/enum'; // Định nghĩa data type của component [data] bằng Typescript

var PropskillSpeakerDetailComponentProps = function PropskillSpeakerDetailComponentProps() {
  _classCallCheck(this, PropskillSpeakerDetailComponentProps);
};

var PropskillSpeakerDetailComponentData = function PropskillSpeakerDetailComponentData() {
  _classCallCheck(this, PropskillSpeakerDetailComponentData);
};

export default defineComponent({
  name: 'PropskillSpeakerDetailComponent',
  components: {
    PropskillCourseInfoComponent: PropskillCourseInfoComponent
  },
  props: {
    modelValueSpeaker: {
      type: InstructorModel,
      default: null,
      required: true
    },
    modelValueCourse: {
      type: CourseModel,
      default: null,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(props) {
    var _useInit = useInit(),
        base = _useInit.base;

    var data = reactive({
      modelValueSpeaker: _objectSpread({}, props.modelValueSpeaker),
      modelValueCourse: _objectSpread({}, props.modelValueCourse),
      isLoading: props.isLoading
    });
    watch(function () {
      return props.isLoading;
    }, function (newValue, oldValue) {
      data.isLoading = newValue;
    });
    return {
      PropskillDataGet: PropskillDataGet
    };
  }
});