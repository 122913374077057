import _objectSpread from "/app/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _classCallCheck from "/app/node_modules/@babel/runtime/helpers/esm/classCallCheck.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
// Composition API
import { defineComponent, reactive, watch } from 'vue';
import { useInit, formatDate } from '@/services/shared/utils';
import { HistoryUpdateStatusModel } from '@/services/features/elearning/elearning.model';
import { StatusRegisterEnum } from '@/services/shared/enum';
import { Constant } from '@/services/shared/constant'; // Định nghĩa data type của component [data] bằng Typescript

var PropskillCourseStatusComponentData = function PropskillCourseStatusComponentData() {
  _classCallCheck(this, PropskillCourseStatusComponentData);
};

var PropskillCourseStatusComponentProps = function PropskillCourseStatusComponentProps() {
  _classCallCheck(this, PropskillCourseStatusComponentProps);
};

export default defineComponent({
  name: 'PropskillCourseStatusComponent',
  components: {},
  props: {
    modelValue: {
      type: HistoryUpdateStatusModel,
      default: [],
      required: false
    },
    status: {
      type: String,
      default: '',
      required: false
    },
    isLoading: {
      type: Boolean,
      default: null
    }
  },
  setup: function setup(props) {
    var _useInit = useInit(),
        base = _useInit.base;

    var data = reactive({
      isLoading: props.isLoading,
      modelValue: _objectSpread({}, props.modelValue),
      status: props.status
    });
    watch(function () {
      return props.isLoading;
    }, function (newValue, oldValue) {
      data.isLoading = newValue;
    });

    var statusGetTitle = function statusGetTitle(status) {
      switch (status) {
        case StatusRegisterEnum.IN_CONTACT:
          return Constant.STATUS_REGISTER.find(function (item) {
            return item.key === StatusRegisterEnum.IN_CONTACT;
          });

        case StatusRegisterEnum.CONTACT:
          return Constant.STATUS_REGISTER.find(function (item) {
            return item.key === StatusRegisterEnum.CONTACT;
          });

        case StatusRegisterEnum.REGISTER:
          return Constant.STATUS_REGISTER.find(function (item) {
            return item.key === StatusRegisterEnum.REGISTER;
          });

        case StatusRegisterEnum.IN_PROGRESS:
          return Constant.STATUS_REGISTER.find(function (item) {
            return item.key === StatusRegisterEnum.IN_PROGRESS;
          });

        case StatusRegisterEnum.RESERVE:
          return Constant.STATUS_REGISTER.find(function (item) {
            return item.key === StatusRegisterEnum.RESERVE;
          });

        case StatusRegisterEnum.COMPLETED:
          return Constant.STATUS_REGISTER.find(function (item) {
            return item.key === StatusRegisterEnum.COMPLETED;
          });

        default:
          return {};
      }
    };

    return {
      data: data,
      statusGetTitle: statusGetTitle,
      formatDate: formatDate
    };
  }
});